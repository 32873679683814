import * as Sentry from '@sentry/gatsby'
import { BrowserTracing } from '@sentry/tracing'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'

Sentry.init({
  dsn: 'https://66ee6ad6858f4d4eab199b5a2943f55d@o1385583.ingest.sentry.io/6705625',
  sampleRate: 1.0, // Adjust this value in production
  release: 'production',
  integrations: [
    new BrowserTracing({ tracingOrigins: ['*'] }),
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ['warn', 'error', 'debug', 'assert'],
    }),
  ],
  // beforeSend(event) { // Modify the event here
  //   if (event.user) { // Don't send user's email address
  //     delete event.user.email;
  //   }
  //   return event;
  // },
})
